
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '@/utils/formatDate'

@Component
export default class InsectHistoryMonitor extends Vue {
  private date: string[] = []
  private loading = false
  private dialogTableVisible = false
  private page = 1
  private size = 10
  private total = 0
  private tableData = []
  private childLoading = false
  private childPage = 1
  private childSize = 50
  private childTotal = 0
  private rowImgId = ''
  private getMore = false

  created () {
    this.getDate()
  }

  // 获取七天前时间
  getDate () {
    const date = new Date()
    const time = date.getTime()
    const beforeMs = time - 3600 * 1000 * 24 * 7
    const beforeDate = date.setTime(beforeMs)
    this.date = [dayFormat(beforeDate), dayFormat(date.setTime(time))]
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(
      this.$apis.ecologyMonitor.selectInsectImgByPage,
      {
        page: this.page,
        size: this.size,
        deviceNumber: this.$route.params.deviceNumber,
        startTime: this.date[0],
        endTime: this.date[1]
      }
    ).then((res) => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }
}
